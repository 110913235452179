.section-facefit {
	background: $color-black;
	display: flex;
	height: auto;
	margin-top: 120px;

	@include mq(tablet-down) {
		margin-top: 50px;
	}


	@include mq(mobile) {
		flex-direction: column;
		height: auto;
	}


	.container {
		max-width: 1300px;
		margin: 0 auto;
		display: flex;
		width: 90%;

		.sub-copy-wrap {
			flex: 0 0 50%;
			justify-content: flex-end;
			padding: 65px 40px 65px 0;

			@include mq(tablet-down) {
				padding: 35px;
			}


			@include mq(mobile) {
				flex: auto;
				padding: 60px 40px;
			}


			.sub-copy {
				max-width: 520px;

				h2, h3 {
					color: $color-white;
					font-size: $font-size-h4;
					font-weight: $font-weight-bold;
					text-transform: uppercase;

					@include mq(mobile) {
						font-size: $font-size-larger;
					}
				}

				h2 {
					margin-bottom: 5px;
				}

				h3 {
					font-weight: $font-weight-light;
					margin-bottom: 55px;

					@include mq(tablet-down) {
						margin-bottom: 30px;
					}


					@include mq(mobile) {
						margin-bottom: 20px;
					}
				}

				p {
					color: $color-white;
					font-size: $font-size-med-large;
					line-height: $line-height-large;
					margin-bottom: 35px;

					@include mq(mobile) {
						font-size: $font-size-standard;
					}
				}

				.btn {
					@extend %animation-right;

					color: $color-white;
					font-size: $font-size-small-med;
					font-weight: $font-weight-regular;
					letter-spacing: normal;
					margin-top: 20px;
					padding: 19px 15px;
					width: 220px;

					@include mq(tablet) {
						margin-top: 10px;
					}


					@include mq(mobile) {
						text-align: left;
						width: 170px;
					}


					&:hover {
						color: $color-link;
					}

					.icon-container {
						float: right;
						display: inline-block;
						overflow: hidden;

						svg {
							color: $color-link;
							height: 8px;
							width: 8px;
						}
					}
				}
			}
		}

		.sub-image {
			background-size: cover;
			background-repeat: no-repeat;
			flex: 0 0 50vw;

			@include mq(mobile) {
				flex: auto;
				height: 75vw;
			}
		}
	}
}
