#sitemap {
	.section-site-map {
		padding: 50px 0;

		.wrap-content {
			@extend %container, %clearfix;

			display: flex;

			h1 {
				margin: 0 0 40px 0;
				text-transform: uppercase;
			}

			h2 {
				margin: 20px 0 10px 0;
				text-transform: uppercase;
				font-size: $font-size-med;
			}

			ol,
			ul {
				margin: 0;
				padding: 0;

				li {
					margin: 0 0 3px 18px;
					padding: 0;
				}
			}

			.sub-pages {
				flex: 1;
			}
		}
	}
}
