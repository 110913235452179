.section-contact {
	background: $color-secondary;
	height: 195px;

	@include mq(mobile) {
		height: auto;
		padding: 45px 0;
	}


	.sub-content-wrapper {
		@extend %container;

		align-items: center;
		display: flex;
		height: 100%;

		@include mq(mobile) {
			align-items: flex-start;
			flex-direction: column;
		}


		& > span {
			color: $color-white;
			font-size: $font-size-h4;
			font-weight: $font-weight-light;

			@include mq(tablet-down) {
				font-size: $font-size-large;
			}


			&.sub-email {
				flex: 1;
				margin-left: 60px;
				padding-right: 30px;

				@include mq(mobile) {
					margin-left: 0;
					margin-top: 10px;
				}
			}

			a {
				color: $color-white;
				font-weight: 500;

				@include mq(tablet-down) {
					display: block;
					margin-top: 20px;
				}


				@include mq(mobile) {
					font-size: $font-size-h3;
				}
			}
		}

		.btn {
			border-color: $color-white;
			color: $color-white;
			letter-spacing: 1px;
			padding: 24px;
			width: 225px;

			@include mq(mobile) {
				display: none;
			}


			.icon-container {
				float: right;

				.svg-icon {
					color: $color-white;
				}
			}
		}
	}
}
