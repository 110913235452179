#contact {
	h1 {
		display: none;
	}

	.section-contact-details {
		background: $color-text-light;

		.sub-wrapper {
			display: flex;
			max-width: 780px;
			padding: 44px 0;

			@include mq(mobile) {
				flex-direction: column;
				padding: 40px 20px;
			}


			h3, span {
				align-self: center;
				color: $color-white;
				flex: 1;
				font-size: $font-size-larger;
				font-weight: $font-weight-light;
				margin-bottom: 0;

				@include mq(mobile) {
					align-self: flex-start;
					font-size: $font-size-med;
				}
			}

			h3 {
				margin-right: 70px;
				padding-right: 30px;

				@include mq(mobile) {
					font-size: $font-size-med;
					margin-right: 0;
					margin-bottom: 15px;
					padding-right: 0;
				}
			}

			span a {
				display: block;
				font-weight: $font-weight-semibold;

				@include mq(mobile) {
					display: inline-block;
				}
			}
		}
	}

	.section-form {
		padding: 57px 0 120px;

		@include mq(mobile) {
			padding: 35px 20px;
		}


		form {
			@extend %container;

			max-width: 780px !important;

			header {
				margin: 0 0 30px;

				@include mq(mobile) {
					margin-bottom: -10px;
				}


				h2 {
					font-size: $font-size-h5;
					letter-spacing: 3px;
					font-weight: $font-weight-bold;
					text-transform: uppercase;
					margin: 0;
				}
			}

			.form-element {
				input, textarea {
					color: rgba($color-text-light, 0.85);
					padding: 0;
					text-align: left;

					&::placeholder {
						color: rgba($color-text-light, 0.85);
					}

					& + label {
						display: none;
					}
				}

				textarea {
					min-height: 80px;

					&:focus ~ label {
						display: none;
					}

					& + label {
						bottom: 25px;
						color: rgba($color-text-light, 0.85);
						display: block;
						font-size: $font-size-large;
						font-weight: $font-weight-regular;
					}
				}
			}
		}

		.sub-btn-wrap {
			text-align: center;

			.btn {
				@include mq(mobile) {
					font-size: $font-size-small;
					font-weight: $font-weight-bold;
					padding: 20px 25px;
					width: 170px;
				}


				svg {
					float: right;
				}
			}
		}

		.wrap-thanks {
			@extend %container;
		}
	}

	.section-bottom {
		display: flex;

		@include mq(mobile) {
			flex-direction: column;
		}


		.section-map {
			display: flex;
			flex: 1;
			flex-direction: column;

			@include mq(mobile) {
				flex-direction: column;
			}


			.sub-address {
				background: $color-white;
				display: flex;
				flex: 1;
				padding: 60px;

				@include mq(mobile) {
					justify-content: flex-start;
					padding: 20px;
				}


				.sub-copy {
					h3 {
						color: $color-text;
						font-size: $font-size-med-large;
						margin-bottom: 30px;
						text-transform: uppercase;

						@include mq(mobile) {
							font-size: $font-size-small-med;
						}
					}

					p {
						color: $color-text;
						font-size: $font-size-larger;
						font-weight: $font-weight-light;
						line-height: $line-height-large;

						@include mq(mobile) {
							font-size: $font-size-small-med;
							line-height: $line-height-base;
						}
					}

					.btn {
						margin-top: 25px;
						width: 230px;

						@include mq(mobile) {
							font-size: $font-size-small;
							font-weight: $font-weight-bold;
							padding: 20px;
							text-align: left;
							width: 170px;
						}


						.icon-container {
							display: inline-block;
							float: right;
							width: 8px;
						}
					}
				}
			}

			#map {
				flex: 1;
				height: 400px;
				min-height: 400px;
				width: 100%;

				@include mq(mobile) {
					flex: auto;
					height: 50vw;
				}
			}
		}

		.section-addresses {
			background: $color-neutral;
			flex: 1;
			padding: 60px;

			@include mq(mobile) {
				padding: 50px 20px;
			}


			.sub-content {
				& > p {
					font-size: $font-size-large;
					margin-bottom: 20px;
				}

				& > h3 {
					color: $color-text;
					font-size: $font-size-med-large;
					font-weight: $font-weight-semibold;
					margin-bottom: 30px;
					text-transform: uppercase;
				}
			}

			ul.list-addresses {
				@extend %list-default;

				li {
					padding: 20px 0;

					h3 {
						color: $color-text;
						font-size: $font-size-larger;
						font-weight: $font-weight-semibold;
						margin-bottom: 10px;

						@include mq(mobile) {
							font-size: $font-size-large;
						}
					}

					.sub-text {
						display: block;
						font-size: $font-size-med;
						margin-bottom: 5px;
					}

					.sub-details {
						display: flex;

						a {
							display: block;
							font-size: $font-size-med;

							&.mod-email {
								&:after {
									content: "|";
									color: $color-text;
									display: inline-block;
									margin: 0 10px;
								}
							}
						}
					}
				}
			}
		}
	}
}
