form {
	.sub-errors p {
		margin: 0 0 5px;
		font-size: $font-size-small;
		color: $color-validation-error;
	}

	.sub-instructions {
		@include mq(mobile) {
			margin-bottom: 46px;
		}
	}

	.form-element {
		position: relative;
		margin: 30px 0;

		&.error {
			label {
				color: $color-validation-error;
			}
		}

		input, textarea {
			width: 100%;
			border: none;
			border-bottom: 1px solid $color-borders-light;
			transition: 0.2s ease;
			padding: 0 10px 0 150px;
			font-size: $font-size-large;
			color: $color-text;
			min-height: 60px;
			outline: none;

			@include mq(mobile) {
				font-size: $font-size-med;
				padding: 0 !important;
				text-align: left !important;
			}


			&:focus {
				border-color: $color-primary;

				+ label {
					color: $color-primary;
					font-size: $font-size-med;
					font-weight: $font-weight-semibold;
				}

				@include mq(mobile) {
					@include placeholder {
						color: $color-primary;
					}
				}
			}

			@include placeholder {
				color: $color-white;
			}


			@include mq(mobile) {
				padding: 0 10px;
				text-align: center;

				@include placeholder {
					color: $color-text;
				}
			}
		}

		label {
			color: $color-text-light;
			font-size: $font-size-med;
			font-weight: $font-weight-light;
			position: absolute;
			bottom: 15px;
			left: 0;
			width: auto;
			display: block;
			transition: 0.2s ease;

			@include mq(mobile) {
				top: -21px;
			}
		}

		textarea {
			min-height: 60px;
			@include mq(mobile) {
				padding: 0 !important;
			}
			+ label {
				bottom: 20px;
			}
		}
	}

	.btn.btn-primary {
		letter-spacing: 1px;
		margin: 35px 0 0;
		padding: 15px 30px;
		text-align: center;
		width: auto;
		margin: 25px 0 0;
		padding: 13px 70px;

		@include mq(mobile) {
			padding: 15px 0;
			width: 100%;
			margin: 55px 0 0;
		}
	}
}

input, select, textarea, button, optgroup {
	font-family: $font-standard !important;
}
