#home {
	.section-hero {
		align-items: center;
		background-size: cover;
		background-repeat: no-repeat;
		display: flex;
		height: 670px;
		position: relative;

		&:after {
			background: linear-gradient(90deg, $color-secondary 50%, rgba($color-secondary, 0.39) 50%);
			bottom: 0;
			content: '';
			height: 7px;
			left: 0;
			position: absolute;
			width: 100%;
		}

		@include mq(tablet-down) {
			background-position: 50% 0px;
			height: auto;
			padding: 45px 0 60px;
			position: relative;

			&:before {
				background: rgba($color-black, 0.45);
				content: '';
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 0;
			}
		}


		.sub-wrapper {
			@include mq(mobile) {
				position: relative;
				z-index: 1;
			}


			h1 {
				color: $color-white;
				font-size: $font-size-hero-med;
				font-weight: $font-weight-thin;
				line-height: 1;
				margin-bottom: 30px;
				position: relative;
				text-transform: uppercase;
				z-index: 999;

				@include mq(tablet-down) {
					font-size: $font-size-hero-med / 1.35;
				}


				@include mq(mobile) {
					font-size: $font-size-h2;
					max-width: 250px;
					width: 100%;
				}


				strong {
					font-size: $font-size-hero-large;
					font-weight: $font-weight-semibold;

					@include mq(tablet-down) {
						font-size: $font-size-hero-large / 1.35;
					}


					@include mq(mobile) {
						font-size: $font-size-h2;
					}
				}
			}

			h3 {
				color: $color-text-lightest;
				font-size: $font-size-large;
				font-weight: $font-weight-regular;
				margin-bottom: 65px;
				position: relative;
				z-index: 999;

				@include mq(mobile) {
					font-size: $font-size-small-med;
					font-weight: $font-weight-light;
					line-height: $line-height-base;
					max-width: 170px;
					margin-bottom: 35px;
					width: 100%;
				}
			}

			.btn {
				position: relative;

				@include mq(mobile) {
					padding: 18px 0;
					width: 170px;
				}


				.icon-container {
					margin-left: 17px;

					@include mq(mobile) {
						float: right;
						margin-right: 10px;
					}
				}
			}
		}
	}

	.section-safety-equipment {
		padding: 75px;
		position: relative;

		@include mq(mobile) {
			background: $color-neutral;
			padding: 20px 0;
		}


		&:before {
			background: $color-neutral;
			content: '';
			height: 540px;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: -1;

			@include mq(mobile) {
				content: none;
			}
		}

		& > h2 {
			@extend %common-subheading;

			color: $color-text-light;
			font-size: $font-size-larger;
			text-align: center;

			@include mq(mobile) {
				display: none;
			}
		}

		ul.list-equipment {
			@extend %container, %product-items;

			margin-top: 80px;
			position: relative;

			.sub-valved-label {
				align-items: center;
				border-radius: 50%;
				border: 1px solid #4990E2;
				color: #4990E2;
				font-size: $font-size-small;
				display: flex;
				height: 80px;
				line-height: 1.3;
				position: absolute;
				right: 15px;
				top: 15px;
				width: 80px;

				p {
					margin-bottom: 0;
				}
			}

			@include mq(mobile) {
				margin-top: 20px;
			}
		}
	}

	.section-useful-info {
		background-position: top -755px right;
		background-repeat: no-repeat;
		padding: 70px 0 110px;

		@include mq(mobile) {
			padding: 50px 0 40px;
		}


		h3 {
			color: $color-text-lighter;
			font-size: $font-size-med;
			font-weight: $font-weight-bolder;
			letter-spacing: 2.58px;
			margin-bottom: 75px;
			text-transform: uppercase;

			@include mq(mobile) {
				margin-bottom: 45px;
			}
		}

		.sub-copy-wrap {
			display: flex;

			@include mq(mobile) {
				flex-direction: column;
			}


			.sub-image {
				flex: 1;
				margin-right: 100px;

				@include mq(mobile) {
					margin-right: 0;
					margin-bottom: 50px;
				}


				img {
					max-width: 100%;
				}
			}

			ul.list-info {
				@extend %list-default;

				flex: 1;

				& > li {
					margin-bottom: 46px;
					width: 100%;

					&:last-child {
						margin-bottom: 0;
					}

					h4 {
						font-weight: $font-weight-light;
						letter-spacing: 1.58px;
						margin-bottom: 10px;
						text-transform: uppercase;

						@include mq(mobile) {
							font-size: $font-size-larger;
						}


						strong {
							font-weight: $font-weight-bolder;
						}

						p {
							margin-bottom: 0;
						}
					}

					.sub-copy {
						margin-bottom: 18px;

						p {
							color: $color-text-lighter;
							font-size: $font-size-small-med;
							font-weight: $font-weight-light;
							line-height: $line-height-base;

							@include mq(mobile) {
								font-size: $font-size-standard;
							}


							&:only-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}
