.products {
	.section-hero {
		background-size: cover;
		background-position: center;
		display: flex;
		height: 470px;
		position: relative;
		padding-top: 70px;

		@include mq(mobile) {
			align-items: center;
			background-position: right top;
			height: 155px;
			padding-top: 0;
		}


		&.mod-large {
			background-color: $color-white;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			height: auto;
			min-height: 700px;

			&:after {
				background: linear-gradient(transparent, $color-white);
				bottom: 0;
				content: '';
				height: 50px;
				left: 0;
				position: absolute;
				width: 100%;
			}

			@media only screen and (max-width: 1366px) {
				background-position: top;

				&:after {
					content: none;
				}
			}

			@include mq(tablet-down) {
				min-height: auto;
				padding: 50px 0;
			}


			@include mq(mobile) {
				background-image: none !important;
				height: 50vw;
				padding: 0;

				.sub-mobile-bg {
					background-repeat: no-repeat;
					background-size: cover;
					height: 100%;
					width: 100%;
				}
			}


			.sub-wrapper {
				@include mq(mobile) {
					display: none;
				}
			}

			&:before {
				content: none;
			}

			h1 {
				color: $color-black;
				font-weight: $font-weight-bold;
				margin-bottom: 45px;
				max-width: 450px;
				width: 100%;

				@include mq(tablet) {
					margin-bottom: 15px;
				}


				.mod-bold:before {
					content: ' ';
				}
			}

			h2 {
				color: $color-white;
				margin-bottom: 25px;

				@include mq(tablet) {
					margin-bottom: 10px;
				}
			}

			.sub-copy {
				max-width: 455px;
				width: 100%;

				p {
					color: $color-black;
					font-size: $font-size-med-large;
					font-weight: $font-weight-regular;

					@include mq(tablet) {
						font-size: $font-size-med;
					}
				}

				.sub-datasheet {
					color: $color-black;
					display: block;
					font-size: $font-size-standard;
					font-weight: $font-weight-bold;
					letter-spacing: 1.87px;
					margin-top: 40px;
					text-transform: uppercase;
					transition: color .3s ease;

					svg {
						height: 14px;
						margin-right: 12px;
						width: 14px;
					}
				}
			}
		}

		&:not(.mod-large) {
			.mod-bold {
				display: block;
				font-weight: $font-weight-bold;
			}
		}

		&:before {
			opacity: 0.78;
			background: linear-gradient(90deg, $color-black 0%, rgba(40, 40, 40, 0.81) 43.13%, rgba(154, 154, 154, 0.29) 72.38%, rgba(213, 213, 213, 0.01) 79.23%, rgba(216, 216, 216, 0) 86.87%, rgba(216, 216, 216, 0) 100%);
			bottom: 0;
			content: '';
			height: 100%;
			left: 0;
			position: absolute;
			width: 100%;
		}

		.sub-wrapper {
			position: relative;
			z-index: 9;

			.sub-jsp {
				position: absolute;
				right: 0;
				top: 0;
				width: 140px;
			}
		}

		p, h1, h2 {
			color: $color-white;
		}

		p {
			font-size: $font-size-med;
			font-weight: $font-weight-semibold;
			line-height: $line-height-larger;
			max-width: 670px;
			width: 100%;

			@include mq(mobile) {
				display: none;
			}


			&:only-child {
				margin-bottom: 0;
			}
		}

		h1 {
			color: $color-white;
			font-size: $font-size-hero-med;
			font-weight: $font-weight-thin;
			line-height: 1;
			letter-spacing: 2px;
			margin-bottom: 30px;
			max-width: 700px;
			text-transform: uppercase;

			@include mq(mobile) {
				font-size: $font-size-largest;
				margin-bottom: 0;
				max-width: auto;
			}
		}

		h2 {
			color: $color-borders;
			font-size: $font-size-med-large;
			letter-spacing: 2.62px;
			text-transform: uppercase;

			@include mq(mobile) {
				font-size: $font-size-small;
				margin-bottom: 5px;
			}
		}
	}

	.section-products {
		background: $color-neutral;
		padding: 60px 0;

		@include mq(mobile) {
			padding: 30px 0;
		}


		.sub-wrapper .sub-copy.mod-mobile p {
			display: none;

			@include mq(mobile) {
				color: $color-text-light;
				display: block;
				font-size: $font-size-standard;
				line-height: $line-height-base;
				margin-bottom: 40px;
			}
		}

		h3 {
			font-size: $font-size-large;
			font-weight: $font-weight-bolder;
			letter-spacing: 2.9px;
			margin-bottom: 60px;
			text-align: center;
			text-transform: uppercase;

			@include mq(mobile) {
				font-size: $font-size-small-med;
				letter-spacing: 2.4px;
				margin-bottom: 20px;
				text-align: left;
			}
		}

		ul.list-products {
			@extend %container, %product-items;

			@include mq(mobile) {
				max-width: 100%;
			}


			& > li {
				.sub-img {
					@include mq(mobile) {
						margin-bottom: 10px;

						img {
							max-height: 150px;
						}
					}
				}

				.sub-copy-wrap .sub-copy.mod-intro {
					@include mq(mobile) {
						margin-bottom: 15px;
					}
				}
			}

			img {
				height: 220px;
			}
		}
	}

	.section-range-features {
		padding-top: 60px;

		&.mod-pad {
			padding-bottom: 60px;
		}

		@include mq(mobile) {
			background: $color-neutral;
			padding: 60px 0;
		}


		h3 {
			@extend %common-subheading;
		}

		ul.list-range-features {
			@extend %list-default;

			display: flex;

			@include mq(mobile) {
				flex-direction: column;
			}


			& > li {
				display: flex;
				flex: 1;

				&:nth-child(3n+2) {
					padding: 0 20px;

					@include mq(mobile) {
						padding: 0;
					}
				}

				.sub-icon {
					align-self: flex-start;
					background-repeat: no-repeat;
					background-size: contain;
					flex: 0 0 55px;
					height: 55px;

					@include mq(tablet-down) {
						height: 45px;
					}


					@include mq(mobile) {
						height: 30px;
					}
				}

				.sub-copy {
					padding-left: 40px;

					@include mq(tablet-down) {
						padding-left: 20px;
					}


					@include mq(mobile) {
						padding-left: 5px;
					}


					h3 {
						font-size: $font-size-small-med;
						letter-spacing: 2px;
						margin-bottom: 25px;
						text-transform: uppercase;

						@include mq(mobile) {
							font-size: $font-size-med;
							letter-spacing: 1.3px;
							margin-bottom: 15px;
						}
					}

					p {
						font-size: $font-size-small-med;
						line-height: $line-height-large;
					}
				}
			}
		}
	}

	.section-products-datasheet {
		@extend %container;

		padding: 80px 0 0;

		@include mq(mobile) {
			padding: 40px 0;
		}


		h3 {
			@extend %common-subheading;
		}

		ul.list-products {
			@extend %product-items;

			margin-left: 0;

			& > li {
				&:hover .sub-datasheet {
					color: $color-link;
				}

				.sub-img {
					.sub-valved-label {
						align-items: center;
						border-radius: 50%;
						border: 1px solid #4990E2;
						color: #4990E2;
						font-size: $font-size-small;
						display: flex;
						height: 80px;
						line-height: 1.3;
						position: absolute;
						right: 15px;
						top: 15px;
						width: 80px;

						p {
							margin-bottom: 0;
						}
					}
				}

				.sub-copy-wrap {
					flex-grow: 0;

					& > p {
						font-size: $font-size-med;
						line-height: $line-height-large;

						span {
							display: block;
						}
					}

					.sub-title {
						font-size: $font-size-largest;
						font-weight: $font-weight-bolder;
						line-height: $line-height-large;
						padding-bottom: 30px;

						@include mq(mobile) {
							font-size: $font-size-larger;
							margin-bottom: 20px;
							padding-bottom: 20px;
						}


						&:after {
							@include mq(mobile) {
								display: block;
								height: 2px;
							}
						}
					}
				}

				.sub-protection, .sub-applications {
					font-size: 13px; // TODO: change to variable
					flex-grow: 1;

					p {
						display: inline-block;
						font-size: 13px; // TODO: change to variable
						margin-bottom: 0;
					}

					strong {
						text-transform: uppercase;
					}

					.pipe {
						display: inline-block;
						margin: 0 9px;
					}
				}

				.sub-protection {
					flex-grow: 0;
					margin-bottom: 20px;
				}

				.sub-datasheet {
					color: $color-black;
					font-size: $font-size-standard;
					font-weight: $font-weight-bold;
					letter-spacing: 1.87px;
					margin-top: 40px;
					text-transform: uppercase;
					transition: color .3s ease;

					@include mq(mobile) {
						margin-top: 20px;
					}


					svg {
						height: 14px;
						margin-right: 12px;
						width: 14px;
					}
				}
			}
		}
	}

	.section-mobile-copy {
		display: none;

		@include mq(mobile) {
			display: block;

			h1 {
				color: $color-black;
				margin-bottom: 45px;
				text-transform: uppercase;
			}

			h2 {
				color: $color-borders;
				font-size: $font-size-small;
				letter-spacing: 1.54px;
				margin-bottom: 10px;
			}

			p {
				font-size: $font-size-standard;
			}

			.sub-datasheet {
				color: $color-black;
				display: block;
				font-size: $font-size-small;
				font-weight: $font-weight-bold;
				letter-spacing: 2px;
				margin-bottom: 50px;
				margin-top: 40px;
				text-transform: uppercase;

				svg {
					height: 14px;
					margin-right: 5px;
					width: 20px;
				}
			}
		}
	}
}
