.footer-main {
	background: $color-white;

	.sub-wrapper {
		border-bottom: 1px solid $color-borders;
		display: flex;
		padding: 55px 0 35px;

		@include mq(mobile) {
			flex-direction: column;
			padding: 35px 0;
		}


		nav {
			display: flex;
			margin-left: auto;

			@include mq(mobile) {
				flex-wrap: wrap;
				margin-left: 0;
				margin-top: 10px;
			}


			ul {
				@extend %list-default;

				margin-left: 100px;

				@include mq(mobile) {
					flex: 0 0 calc(50% - 30px);
					margin: 25px 30px 0 0;

					&:last-child {
						margin-bottom: 0;
					}
				}


				li {
					margin-bottom: 10px;

					@include mq(mobile) {
						margin-bottom: 5px;
					}


					&:first-child {
						color: $color-text-light;
						font-weight: $font-weight-bold;
						letter-spacing: 2.15px;
						margin-bottom: 25px;
						text-transform: uppercase;

						@include mq(mobile) {
							margin-bottom: 10px;
						}
					}

					a {
						color: $color-borders;
					}
				}
			}
		}

		.sub-copyright {
			max-width: 450px;

			.logo {
				height: 48px;
				margin-bottom: 60px;
				width: 162px;

				@include mq(mobile) {
					height: 40px;
					margin-bottom: 30px;
					width: 130px;
				}
			}

			p {
				color: $color-borders;
				font-size: $font-size-small;
				line-height: 1.25;
			}

			@include mq(mobile) {
				font-size: $font-size-small;
			}


			span {
				color: $color-borders;
				font-size: $font-size-small;
			}
		}
	}
}

.footer-secondary {
	background: $color-white;
	padding: 35px 0;

	.sub-wrapper {
		p {
			color: $color-borders;
			font-size: $font-size-small;
			margin-bottom: 5px;
		}
	}
}
