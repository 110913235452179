html {
	font-size: $font-size-base;

	body {
		line-height: $line-height-base;
		font-weight: $font-weight-regular;
		font-family: $font-standard;
		background: $color-body;
		color: $color-text;
		font-size: $font-size-standard;
		margin: 0;

		a.anchor {
			display: block;
			visibility: hidden;
			position: relative;
			top: -140px; // Usually height of the header
	
		}

		&.no-scroll {
			bottom: 0;
			height: 100%;
			left: 0;
			overflow: hidden;
			position: fixed;
			right: 0;
			top: 0;
			width: 100%;
		}
	}

	* {
		box-sizing: border-box;
	}

	.sub-wrapper {
		@extend %container;
	}
}
