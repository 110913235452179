.generic-page {
	p {
		font-size: $font-size-med;
		line-height: $line-height-larger;
		margin-bottom: 40px;

		@include mq(mobile) {
			font-size: $font-size-standard;
			margin-bottom: 30px;
			line-height: $line-height-base;
		}


		strong {
			font-size: $font-size-larger;

			@include mq(mobile) {
				font-size: $font-size-standard;
			}
		}

		&:last-of-type {
			margin-bottom: 0;

			@include mq(mobile) {
				margin-bottom: 40px;
			}
		}
	}

	.section-hero {
		background-position: top right;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		height: 121px;
		position: relative;

		@include mq(mobile) {
			height: 55px;
		}


		&:after {
			background: rgba($color-secondary, 0.28);
			bottom: 0;
			content: '';
			height: 8px;
			left: 0;
			position: absolute;
			width: 100%;

			@include mq(mobile) {
				height: 5px;
			}
		}

		.sub-wrapper {
			align-self: center;

			h1 {
				color: $color-white;
				font-size: $font-size-largest;
				font-weight: $font-weight-light;
				margin-bottom: 0;
				text-transform: uppercase;

				@include mq(mobile) {
					font-size: $font-size-standard;
					font-weight: $font-weight-semibold;
				}


				.mod-bold {
					font-weight: $font-weight-bold;

					&:before {
						content: ' ';
					}
				}
			}
		}
	}

	.section-main-copy {
		padding: 70px 0;

		@include mq(mobile) {
			padding: 35px 0;
		}


		.sub-copy-wrap {
			display: flex;

			@include mq(mobile) {
				flex-direction: column;
			}


			.sub-copy {
				padding-right: 100px;

				@include mq(tablet-down) {
					padding-right: 50px;
				}


				@include mq(mobile) {
					padding-right: 0;
				}


				h2 {
					@extend %common-subheading;

					font-size: 22px;
					padding-bottom: 35px;
					position: relative;

					@include mq(mobile) {
						font-size: $font-size-large;
						margin-bottom: 35px;
						padding-bottom: 30px;
						padding-right: 20px;
					}


					&:after {
						background: $color-borders;
						bottom: 0;
						content: '';
						height: 2px;
						left: 0;
						position: absolute;
						width: 40px;
					}
				}
			}

			.sub-image img {
				@include mq(tablet-down) {
					max-width: 360px;
				}


				@include mq(mobile) {
					max-width: 100%;
				}
			}
		}
	}

	.section-secondary-copy, .section-third-copy {
		padding: 0 0 70px;

		@include mq(mobile) {
			padding: 0 0 35px;
		}


		h3 {
			@extend %common-subheading;

			font-size: 22px;
			line-height: $line-height-large;
			margin-bottom: 35px;

			@include mq(mobile) {
				font-size: $font-size-small-med;
				line-height: $line-height-base;
				margin-bottom: 20px;
			}
		}

		.sub-copy-wrap {
			display: flex;

			@include mq(mobile) {
				flex-direction: column;

				.sub-image {
					margin-top: 30px;
				}
			}


			.sub-image img {
				@include mq(tablet-down) {
					max-width: 360px;
					width: 100%;
				}


				@include mq(mobile) {
					img {
						max-width: 100%;
					}
				}
			}

			.sub-copy {
				padding-right: 120px;

				@include mq(tablet-down) {
					padding-right: 50px;
				}


				@include mq(mobile) {
					padding-right: 0;
				}


				ul {
					margin: -20px 0 20x;

					@include mq(mobile) {
						margin-left: 5px;
						margin-top: -10px;
					}


					li {
						font-size: $font-size-med;
						margin-bottom: 15px;

						@include mq(mobile) {
							font-size: $font-size-standard;
						}
					}
				}
			}
		}
	}

	.section-third-copy {
		padding-bottom: 100px;

		@include mq(mobile) {
			padding-bottom: 70px;
		}
	}
}

#about {
	.sub-copy-wrap .sub-copy ul {
		@extend %list-default;

		column-count: 4;
		max-width: 700px;
		margin-bottom: 70px;
		width: 100%;

		@include mq(mobile) {
			column-count: 2;
			margin-bottom: 30px;
		}


		&:last-child {
			column-count: 2;
		}

		li {
			color: $color-text;
			font-size: $font-size-med;
			line-height: $line-height-larger;

			@include mq(mobile) {
				font-size: $font-size-standard;
				font-weight: $font-weight-light;
				line-height: $line-height-base;
			}
		}
	}
}
