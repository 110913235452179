#mediaCentre {
	background: $color-neutral;

	@include mq(mobile) {
		background: $color-body;
	}


	.section-hero {
		background-position: top right;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		height: 121px;
		position: relative;

		@include mq(mobile) {
			height: 55px;
		}


		&:after {
			background: rgba($color-secondary, 0.28);
			bottom: 0;
			content: '';
			height: 8px;
			left: 0;
			position: absolute;
			width: 100%;

			@include mq(mobile) {
				height: 5px;
			}
		}

		.sub-wrapper {
			align-self: center;

			h1 {
				color: $color-white;
				font-size: $font-size-largest;
				font-weight: $font-weight-light;
				margin-bottom: 0;
				text-transform: uppercase;

				@include mq(mobile) {
					font-size: $font-size-standard;
					font-weight: $font-weight-semibold;
				}


				.mod-bold {
					font-weight: $font-weight-bold;

					&:before {
						content: ' ';
					}
				}
			}
		}
	}

	.section-main {
		@extend %container-small;

		padding: 55px 0;

		@include mq(mobile) {
			padding: 30px 0;
		}


		h2 {
			@extend %common-subheading;

			font-size: 22px;
			padding-bottom: 35px;
			position: relative;

			@include mq(mobile) {
				font-size: $font-size-large;
				margin-bottom: 35px;
				padding-bottom: 30px;
				padding-right: 20px;
			}


			&:after {
				background: $color-borders;
				bottom: 0;
				content: '';
				height: 2px;
				left: 0;
				position: absolute;
				width: 40px;
			}
		}

		p {
			color: $color-text-light;
			font-size: $font-size-med;
			line-height: $line-height-large;

			@include mq(mobile) {
				font-size: $font-size-standard;
				font-weight: $font-weight-semibold;
			}
		}
	}

	.section-form {
		@extend %container-small;

		padding: 0 0 70px;

		.sub-instructions p {
			font-size: $font-size-small-med;
			font-weight: $font-weight-bold;
			letter-spacing: 2px;
			text-transform: uppercase;
		}

		form {
			.sub-account-text {
				span {
					color: $color-primary;
					display: block;
					font-size: $font-size-med;
				}
			}

			.form-element {
				&.mod-hidden {
					display: none;
				}

				&.mod-show {
					display: block;
				}

				input, textarea {
					background: none;
					padding: 6px 10px 0 220px;
				}

				.sub-radio-wrap {
					align-items: center;
					display: flex;
					height: 40px;
					max-width: 500px;

					label {
						align-items: center;
						display: flex;
						font-size: $font-size-med;
						flex: 1;
						position: relative;
						z-index: 999;

						&.sub-radio {
							bottom: 0;
							position: relative;

							input {
								flex-basis: 20px;
								height: 16px;
								min-height: auto;
								margin-right: 15px;
							}
						}
					}
				}
			}

			.sub-checkbox-wrap {
				color: $color-primary;
				display: flex;
				font-size: $font-size-med;
				margin: 50px 0 25px;
				position: relative;

				input {
					opacity: 0;
					width: 0;
				}

				.sub-checkbox {
					cursor: pointer;
					margin-left: -28px;
					padding-left: 30px;
				}

				&:before {
					background: $color-white;
					border: 1px solid $color-borders-light;
					content: '';
					display: inline-block;
					height: 17px;
					margin-right: 9px;
					margin-top: 4px;
					transition: background .3s ease;
					min-width: 17px;
				}

				&:after {
					content: '';
					opacity: 0;
					transition: opacity .3s ease;
				}

				&.mod-checked {
					&:before {
						background: $color-secondary;
					}

					&:after {
						border: solid $color-white;
						border-width: 0 2px 2px 0;
						display: block;
						height: 9px;
						left: 7px;
						opacity: 1;
						position: absolute;
						top: 6px;
						transform: rotate(45deg);
						width: 4px;
					}
				}
			}

			.btn {
				letter-spacing: 1px;
				margin: 35px 0 0;
				padding: 15px 30px;
				text-align: center;
				width: auto;
			}

			.sub-errors {
				strong {
					font-size: $font-size-med;
				}

				span {
					color: rgba($color-error, 0.7);
					display: block;
				}
			}
		}

		.sub-thanks {
			display: none;
			font-size: $font-size-h3;
			font-weight: $font-weight-bold;
			line-height: $line-height-larger;
		}
	}

	.section-downloads {
		@extend %container-small;

		padding-bottom: 130px;
		position: relative;

		@include mq(mobile) {
			background: $color-neutral;
			max-width: 100%;
			padding: 40px 20px 100px;
		}


		&.mod-blocked {
			opacity: 0.4;
			pointer-events: none;

			ul.list-downloads {
				li, a.btn {
					pointer-events: none;
				}
			}
		}

		header {
			display: flex;

			@include mq(mobile) {
				flex-direction: column;
			}


			.sub-subtitle {
				@extend %common-subheading;

				font-size: $font-size-small-med;
				flex: 1;
				margin-bottom: 40px;
				text-transform: uppercase;

				@include mq(mobile) {
					margin-bottom: 15px;
				}
			}

			.download-type {
				appearance: none;
				background: url("../assets/images/arrow-down.png") no-repeat top 50% right;
				border: 0;
				color: $color-text-lighter;
				font-size: $font-size-med;
				height: 30px;
				padding: 0;
				padding-right: 30px;

				&:focus {
					background-color: none;
					border: none;
					outline: none;
				}

				@include mq(mobile) {
					font-size: $font-size-small;
					margin-bottom: 30px;
				}
			}
		}

		.sub-file-container {
			position: relative;

			ul.list-downloads {
				@extend %list-default;

				position: relative;
				z-index: 99;

				li {
					align-items: center;
					background: $color-white;
					display: flex;
					height: 70px;
					margin-bottom: 8px;
					padding: 0 20px;

					@include mq(mobile) {
						align-items: flex-start;
						flex-direction: column;
						height: auto;
						margin-bottom: 16px;
						padding: 11px;
					}


					.sub-type {
						color: $color-borders;
						flex-basis: 100px;
						letter-spacing: 0.88px;
						text-transform: uppercase;

						@include mq(mobile) {
							display: block;
							flex-basis: auto;
						}
					}

					.sub-title {
						font-size: $font-size-standard;
						font-weight: $font-weight-bold;
						flex: 1;
						letter-spacing: 0.88px;
						text-transform: uppercase;

						@include mq(mobile) {
							display: block;
							margin-bottom: 15px;
						}
					}
				}
			}

			.sub-no-files {
				align-items: center;
				background-color: #d9edf7;
				border-color: #bce8f1;
				border-radius: 4px;
				border: 1px solid transparent;
				color: #31708f;
				display: flex;
				left: 0;
				padding: 15px;
				position: absolute;
				text-align: center;
				top: 0;
				width: 100%;
				z-index: 0;

				p {
					margin-bottom: 0;
				}
			}
		}

		.loading_overlay {
			display: none;
			height: calc(100% - 130px);
			left: 0;
			position: absolute;
			text-align: center;
			top: 0;
			width: 100%;
			z-index: 9999;

			img {
				position: absolute;
				top: 50%;
				width: 60px;
			}
		}
	}
}
