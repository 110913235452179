// Define placeholders within here to be used within further sass.
%container {
	width: $container-width;
	max-width: $container-max-width;
	margin: 0 auto;

	// Example of how to extend container. Used via `@extend %container-large`
	&-large {
		@extend %container;

		max-width: $container-max-width-large;
	}

	&-small {
		@extend %container;

		max-width: $container-max-width-small;
	}

	@include mq(mobile) {
		width: $container-width-mobile;
		max-width: $container-max-width-mobile;
		margin: 0 auto;
	}
}

%clearfix {
	zoom: 1;

	&:after {
		clear: both;
	}

	&:before,
	&:after {
		content: "";
		display: table;
	}
}

%noselect {
	-webkit-touch-callout: none;
	user-select: none;
}

%nofocus {
	&:focus {
		outline-style: none;
		box-shadow: none;
	}
}

%list-default {
	margin: 0;
	padding: 0;
	list-style: none;

	& > li {
		margin: 0;
		padding: 0;
	}
}

%common-subheading {
	color: $color-black;
	font-size: $font-size-large;
	font-weight: $font-weight-bolder;
	letter-spacing: 3.51px;
	line-height: $line-height-med;
	margin-bottom: 60px;
	text-transform: uppercase;

	@include mq(mobile) {
		letter-spacing: 1.64px;
		margin-bottom: 40px;
	}
}

%product-items {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	width: 100%;

	@include mq(mobile) {
		flex-direction: column;
	}


	& > li {
		background: $color-white;
		border: 1px solid rgba($color-link, 0.35);
		display: flex;
		flex-direction: column;
		margin: 0 0 39px;
		min-height: 544px;
		padding: 40px 33px;
		position: relative;
		transition: all .3s ease;
		width: 31.333%;

		@include mq(tablet-down) {
			margin-right: 3% !important;
			width: 48%;
		}


		@include mq(mobile) {
			margin-bottom: 25px;
			min-height: auto;
			padding: 20px;
			width: 100%;
		}


		&:nth-child(3n+2) {
			margin: 0 3% 39px;

			@include mq(tablet-down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		&:nth-child(2n) {
			@include mq(tablet) {
				margin-right: 0 !important;
			}
		}

		&:hover {
			border: 1px solid $color-text-lightest;
			box-shadow: 0 0 10px 3px rgba($color-black, 0.1);
		}

		.sub-link {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}

		.sub-img {
			display: flex;
			justify-content: center;
			margin-bottom: 35px;
			min-height: 220px;
			text-align: center;

			@include mq(mobile) {
				min-height: auto;
			}


			img {
				align-self: center;
				max-width: 100%;

				@include mq(mobile) {
					max-height: 180px;
				}
			}
		}

		.sub-copy-wrap {
			display: flex;
			flex-grow: 1;
			flex-direction: column;

			.sub-title {
				font-weight: $font-weight-light;
				letter-spacing: 1.93px;
				margin-bottom: 40px;
				position: relative;
				text-align: left;
				text-transform: uppercase;

				.sub-hairline {
					bottom: 0;
					height: 3px;
					left: 0;
					position: absolute;
					width: 20px;
				}

				@include mq(mobile) {
					font-size: $font-size-large;
					margin-bottom: 0;
				}


				&.mod-product {
					font-size: $font-size-h3;
					font-weight: $font-weight-bolder;
					margin-bottom: 25px;
					padding-bottom: 30px;
					padding-right: 20px;

					@include mq(mobile) {
						font-size: $font-size-large;
						margin-bottom: 15px;
						padding-bottom: 0;
					}
				}

				strong {
					display: block;
					font-weight: $font-weight-bold;

					@include mq(mobile) {
						display: inline-block;
					}
				}
			}

			.sub-copy {
				flex-grow: 1;
				height: 80px;
				margin-bottom: 30px;
				overflow: hidden;

				@include mq(mobile) {
					margin-bottom: 30px;
				}


				p {
					color: $color-text-lighter;
					font-size: $font-size-med;
					font-weight: $font-weight-light;
					line-height: $line-height-large;

					&:only-child {
						@include mq(mobile) {
							font-size: $font-size-standard;
							margin-bottom: 0;
						}
					}
				}

				&.mod-intro p strong {
					font-weight: $font-weight-light;
				}
			}

			.btn {
				z-index: 9;
			}
		}
	}
}

// Animations

%animation-right {
	overflow: hidden;

	&:hover svg {
		animation: arrow-anim-right 1s;
		color: $color-primary;
	}
}
