/* COLOURS */
$color-black: #000000;
$color-white: #ffffff;

$color-body: $color-white;

$color-neutral: #F5F5F5;
$color-neutral-dark: #607D8B;

$color-borders: #a3a3a3;
$color-borders-light: #c8c8c8;

$color-text: $color-black;
$color-text-light: #14242D;
$color-text-lighter: #4A4A4A;
$color-text-lightest: #DADADA;

$color-headings: $color-black;

$color-error: #ff0000;

$color-primary: $color-text-light;
$color-secondary: #4A90E2;

$color-buttons: $color-primary;

$color-link: $color-secondary;
$color-link-hover: lighten($color-link, 5%);
$color-link-active: $color-link-hover;

$color-validation-error: #cd0000;
$color-validation-success: #4eb73c;

/* FONTS */
$font-standard: "proxima-nova", Arial, Helvetica, Sans-serif;

$font-base: 14;

$font-size-base: $font-base * 1px;
$font-size-standard: 14 / $font-base * 1rem;
$font-size-tiny: 10 / $font-base * 1rem;
$font-size-small: 12 / $font-base * 1rem;
$font-size-small-med: 15 / $font-base * 1rem;
$font-size-med: 16 / $font-base * 1rem;
$font-size-med-large: 17 / $font-base * 1rem;
$font-size-large: 18 / $font-base * 1rem;
$font-size-larger: 20 / $font-base * 1rem;
$font-size-largest: 27 / $font-base * 1rem;

$font-size-h1: 36 / $font-base * 1rem;
$font-size-h2: 30 / $font-base * 1rem;
$font-size-h3: $font-size-largest;
$font-size-h4: 25 / $font-base * 1rem;
$font-size-h5: $font-size-med;
$font-size-h6: $font-size-standard;

$font-size-hero-standard: $font-size-larger;
$font-size-hero-small: 20 / $font-base * 1rem;
$font-size-hero-med: 61 / $font-base * 1rem;
$font-size-hero-large: 83 / $font-base * 1rem;

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;

$headings-font: $font-standard;
$headings-font-weight: $font-weight-bold;
$headings-color: $color-black;
$headings-line-height: 27px;

$line-height-base: 25px;
$line-height-med: 23px;
$line-height-large: 28px;
$line-height-larger: 33px;

$radius-button: 0;
$padding-button: 19px 24px;
$color-buttons: transparent;
$text-size-button: $font-size-small;

/* STRUCTURE */
$viewport-width: 100%;
$container-width: 90%;
$container-max-width: 1300px;
$container-max-width-small: 960px;
$container-max-width-large: 1350px;

/* MOBILE STRUCTURE */
$container-width-mobile: 100%;
$container-gutter-mobile: 35px;
$container-max-width-mobile: calc(100% - #{$container-gutter-mobile});

/* MEDIA QUERY */
$mq-desktop-size: $container-max-width;
$mq-tablet-size: 1024px;
$mq-mobile-size: 768px;
