@keyframes arrow-anim-right {
	from {
		transform: translateX(0%);
	}

	40% {
		transform: translateX(150%);
	}

	50% {
		opacity: 0;
		transform: translateX(-90%);
	}

	70% {
		opacity: 1;
		transform: translateX(0%);
	}
}
