.header-main {
	background: $color-white;
	height: 84px;
	position: relative;

	@include mq(tablet-down) {
		height: 50px;
	}


	.sub-header-wrapper {
		@extend %container-large;

		display: flex;
		height: 100%;

		.logo {
			align-items: center;
			display: flex;

			svg {
				height: 48px;
				width: 162px;
				margin-top: -3px;

				@include mq(tablet-down) {
					height: 35px;
					width: 110px;
				}
			}
		}

		nav {
			display: flex;
			flex: 1;
			justify-content: flex-end;

			@include mq(tablet-down) {
				background: $color-white;
				display: none;
				height: 100vh;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 999;
			}


			ul {
				@extend %list-default;

				padding-top: 28px;

				@include mq(tablet-down) {
					background: $color-white;
					padding: 70px 27px;
					width: 100%;

					&.list-nav {
						height: 100vh;
						overflow-y: scroll;
					}
				}


				li {
					display: inline-block;
					margin-right: 37px;

					@include mq(tablet-down) {
						display: block;
						margin-right: 0;
					}


					&:last-child {
						margin-right: 0;
					}

					&.active > a {
						color: rgba($color-text-lighter, 0.5);
					}

					a {
						color: $color-text-light;
						font-size: $font-size-small;
						font-weight: $font-weight-semibold;
						letter-spacing: 1px;
						text-transform: uppercase;
						transition: color .3s ease;

						@include mq(tablet-down) {
							border-bottom: 1px solid $color-text-lightest;
							color: #00275D;
							display: block;
							font-size: $font-size-small-med;
							padding: 13px 0;
						}


						&:hover {
							color: rgba($color-text-lighter, 0.5);
						}
					}

					&.mod-sub-nav {
						height: 47px;
						position: relative;

						@include mq(tablet-down) {
							height: auto;
						}


						&:hover ul.list-sub-nav {
							max-height: 300px;
							overflow: visible;
							pointer-events: all;
							padding: 25px;
							z-index: 9999;

							& > li a {
								opacity: 1;
							}

							&:before, &:after {
								opacity: 1;
							}

							@include mq(tablet-down) {
								max-height: 0;
								overflow: hidden;
								padding: 0;
							}
						}

						span {
							font-size: $font-size-small;
							font-weight: $font-weight-semibold;
							letter-spacing: 1px;
							position: relative;
							text-transform: uppercase;

							@include mq(tablet-down) {
								border-bottom: 1px solid $color-text-lightest;
								color: #00275D;
								display: block;
								font-size: $font-size-small-med;
								padding: 13px 0;
								transition: border .3s ease;
							}


							&.mod-active {
								border-bottom: 1px solid transparent;
								color: rgba($color-text-lighter, 0.5);
							}

							svg {
								display: none;
								height: 8px;
								position: absolute;
								right: 0;
								top: 50%;
								transform: translateY(-50%);
								width: 8px;
								z-index: 999;
							}
						}

						&.active {
							color: rgba($color-text-lighter, 0.5);
						}
					}

					ul.list-sub-nav {
						@extend %list-default;

						background: $color-white;
						box-shadow: 0 0 12px 0 rgba($color-black, 0.25);
						left: 0;
						max-height: 0;
						margin-top: 20px;
						overflow: hidden;
						padding: 0 25px;
						pointer-events: none;
						position: absolute;
						transition: all 0.3s ease;
						width: 240px;
						z-index: 9999;

						@include mq(tablet-down) {
							padding: 0;
							position: relative;
							max-height: 0;
							margin: 0;
							width: auto;
							box-shadow: none;
						}


						&.mod-active {
							@include mq(tablet-down) {
								border-bottom: 1px solid $color-text-lightest;
								max-height: 300px !important;
								padding: 0 0 30px !important;
							}
						}

						&:before {
							background: $color-white;
							box-shadow: 0px -2px 12px 0 rgba($color-black, 0.25);
							content: "";
							height: 30px;
							opacity: 0;
							position: absolute;
							top: -8px;
							transform: rotate(45deg);
							transition: opacity .3s ease;
							width: 30px;
							z-index: -1;

							@include mq(tablet-down) {
								content: none;
							}
						}

						&:after {
							background: $color-white;
							content: '';
							height: 70px;
							left: 0;
							opacity: 0;
							position: absolute;
							top: 0;
							transition: opacity .3s ease;
							width: 100%;
							z-index: -1;

							@include mq(tablet-down) {
								content: none;
							}
						}

						li {
							width: 100%;

							a {
								display: block;
								opacity: 0;
								padding: 15px 0;
								transition: opacity 0.25s .4s ease;

								@include mq(tablet-down) {
									border: none;
								}
							}
						}
					}
				}
			}
		}
	}

	.sub-mobile-button {
		cursor: pointer;
		display: none;
		height: 15px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 23px;
		z-index: 999;

		@include mq(tablet-down) {
			display: block;
			right: 40px;
		}


		@include mq(mobile) {
			right: 10px;
		}


		.sub-bar {
			background: $color-secondary;
			height: 2px;
			transition: all .3s ease;
			width: 100%;

			&.mod-bar-2 {
				margin-top: 10px;
			}
		}

		&.mod-active {
			.sub-bar {
				transform-origin: 2px 0px;
				transform: rotate(45deg);

				&.mod-bar-2 {
					transform: rotate(-45deg) translate(-2px, 0px);
				}
			}
		}
	}
}

.section-cookies {
	align-items: center;
	background: $color-neutral-dark;
	display: flex;
	height: 75px;

	@include mq(tablet-down) {
		height: auto;
		padding: 10px 0 20px;
	}


	&.mod-hide {
		display: none;
	}

	.sub-content {
		@extend %container-large;

		display: flex;

		@include mq(tablet-down) {
			flex-direction: column;
		}


		.sub-text {
			flex: 1;

			& > p {
				color: $color-white;
				font-size: $font-size-small;
				margin-bottom: 0;
				line-height: 1.25;

				@include mq(tablet-down) {
					color: rgba($color-white, 0.7);
					font-size: $font-size-small;
				}


				a {
					color: $color-white;
					font-weight: $font-weight-semibold;

					@include mq(tablet-down) {
						color: rgba($color-white, 0.7);
					}
				}
			}
		}

		.sub-btns {
			align-items: center;
			display: flex;
			margin-left: 140px;

			@include mq(tablet-down) {
				margin-left: 0;
				margin-top: 20px;
			}


			.btn {
				background: none;
				border: 2px solid rgba($color-white, 0.25);
				color: $color-white;
				display: inline-block;
				font-size: $font-size-standard;
				font-family: $headings-font !important;
				padding: 5px 0;
				transition: background .3s ease;
				width: 130px;

				@include mq(tablet-down) {
					font-size: $font-size-small;
					padding: 4px 0;
					width: 115px;
				}


				&:first-of-type {
					margin-right: 10px;
				}

				&:hover {
					background: rgba($color-white, 0.25);
				}
			}
		}
	}
}
